import type { PropsWithChildren } from 'react';
import { createContext, useContext, useState } from 'react';
import type { Theme } from './theme';
import { createInternalApi } from '~/api/internal.api';

type ThemeContext = {
  theme: Theme,
  setTheme: ( theme: Theme ) => void;
}

const ThemeContext = createContext<ThemeContext>( {
  theme: 'system',
  setTheme( _: Theme ) {
    throw new Error( 'ThemeContext: Missing ThemContextProvider' );
  }
} );

type ThemeContextProviderProps = PropsWithChildren<{
  defaultTheme?: Theme
}>

export const ThemeContextProvider = ( { defaultTheme = 'dark', children }: ThemeContextProviderProps ) => {
  const [ theme, setTheme ] = useState<Theme>( defaultTheme );
  const internalApi = createInternalApi();

  return (
    <ThemeContext.Provider value={ {
      theme,
      setTheme: ( theme: Theme ) => {
        setTheme( theme );

        const activeTheme =
          theme === 'system' && window.matchMedia( '(prefers-color-scheme: dark)' ).matches ? 'dark' : theme !== 'system' ? theme : 'light';

        if ( activeTheme === 'dark' && !document.documentElement.classList.contains( 'dark' ) ) {
          document.documentElement.classList.add( 'dark' );
        } else if ( activeTheme !== 'dark' ) {
          document.documentElement.classList.remove( 'dark' );
        }

        internalApi.updateTheme( theme );
      }
    } }>
      { children }
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext( ThemeContext );